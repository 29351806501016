import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react';
import { legacy_createStore as createStore } from 'redux';
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from "./reducers";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import AdminLayout from "layouts/Admin.js";
import Login from "views/auth/Login";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Admin from "layouts/Admin";
import ErrorFallback from "views/fallbackUI/ErrorFallback";

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools()
);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
      <ToastContainer/>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/dashboard" component={Admin} />
        <Route exact path="/setup-club" component={Admin} />
        <Route exact path="/view-clubs" component={Admin} />
        <Route exact path="/overall-reports" component={Admin} />
        <Route exact path="/setup-master-account" component={Admin} />
        <Route exact path="/manage-admin-users" component={Admin} />
        <Route exact path="/club-tables" render={() => <Admin/>} />
        <Route exact path="/edit-club" render={() => <Admin type="editclub" />} />
        <Route exact path="/digital-sign-categories" render={() => <Admin perspective="club"/>} />
        <Route exact path="/master-templates" render={() => <Admin perspective="master" />} />
        <Route exact path="/master-digital-sign" render={() => <Admin perspective="master" />} />
        <Route exact path="/app-update" render={() => <Admin />} />
        <Route exact path="/digital-signs-list" render={() => <Admin perspective="club"/>} />
        <Route exact path="/club-report" render={() => <Admin/>} />
        <Route exact path="/club-screens" render={() => <Admin/>} />
        <Route exact path="/promocodes" render={() => <Admin/>} />
        <Route exact path="/booking-details" render={() => <Admin />} />
        <Route exact path="/something-went-wrong" component={ErrorFallback} />
        
        <Redirect from="/" to="/login" />
        <Route path="/" render={(props) => <AdminLayout {...props} />} />
      </Switch>
    </BrowserRouter>
    </PersistGate>
  </Provider>
);
