import React, {useState, useEffect} from "react";
import { Card } from "primereact/card";
import { getClubUsers, deleteClubUser, addClubUser, updateClubUserData } from "api/clubusers";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import '../../assets/css/custom.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import {toast} from "react-toastify";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Tag } from "primereact/tag";
import { validateEmail } from "functions/validationUtils";
import { useDispatch } from 'react-redux';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import userTextData from '../../assets/json/patextdata.json';
const ClubUserList = () => {
    const [clubUsers, setClubUsers] = useState(null);
    const user = useSelector((state) => state.user);
    const searchParams = new URLSearchParams(window.location.search);
    const [first, setFirst] = useState(0);
    const club_id = searchParams.get('id');
    const nousers = searchParams.get('nousers');
    const [isdeleted, setIsdeleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [role, setRole] = useState('');
    const [notification, setNotification] = useState(0);
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [clubUserID, setClubUserId] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        getClubUsers(club_id, user?.authtoken)
        .then((response) => {
            if(response.status === 200) {
                setClubUsers(response.data.club_users);
            }
        })
        .catch((error) => {
            if (error.response.status === 404 && nousers) {
                setVisible(true);
                setClubUsers(null);
            }
            if(error.response.status === 400) {
                localStorage.clear();
                dispatch({
                  type: 'LOGOUT',
                  payload: {
                  },
                });
                history.push('/login');
            }
        })
    },[isdeleted, loading]);
    const handleEditClubUser = (clubUserData) => {
        setUsername(clubUserData.UserName);
        setEmail(clubUserData.Email);
        setMobile(clubUserData.Mobile);
        setRole(clubUserData.Role.toString());
        setNotification(clubUserData.Notification);
        setClubUserId(clubUserData.Userid);
        setLoading(false);
        setIsEdit(true);
        setVisible(true);
        setErrors({});
    }

    const actionButtons = (rowData) => {
        return(
          <>
            <Button type="button" className="p-button-sm p-button-text" icon="pi pi-pencil" onClick={()=> handleEditClubUser(rowData)}></Button>
            <Button type="button" className="p-button-sm p-button-text" icon="pi pi-trash" onClick={()=>confirm(rowData)}></Button>
          </>
        )
    }
    const handleClubUSerDelete = (rowData) => {
        deleteClubUser(rowData, user?.authtoken)
        .then((response) => {
            if(response.status === 200) {
                setIsdeleted(!isdeleted);
                toast.success(userTextData.clubUser.clubUserDeleteMsg);
            }
        }).catch((error) => {
            dispatch({
                type: 'IS_ERROR',
                payload: {
                  error: error
                },
            });
            history.push("/something-went-wrong");
        })
    }
    const handleAccept = (rowData) => {
        handleClubUSerDelete(rowData);
    }
    
    const reject = () => {
        
    }
    const confirm = (rowData) => {
        confirmDialog({
            message: userTextData.clubUser.clubUserDeleteCnfmMsg,
            header: userTextData.clubUser.clubUserDeleteHeader,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            draggable: false,
            closeOnEscape: true,
            accept: () => handleAccept(rowData), 
            reject
        });
    };
    const validateForm = () => {
        const newErrors = {};
        if (!username) {
          newErrors.username = userTextData.clubUser.usernameErrorMsg;
        }
        if(!(isValidPhoneNumber(mobile))) {
          newErrors.mobile = userTextData.clubUser.mobileErrorMsg;
        }
        // if(mobile.length !== 10) {
        //     newErrors.mobile = "please enter a valid mobile number";
        // }
        if(role === '') {
          newErrors.role = userTextData.clubUser.roleErrorMsg;
        }
        if(notification === '') {
            newErrors.notification = userTextData.clubUser.notificationErrorMsg;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    };
    const emptyTheForm = () => {
        setUsername("");
        setEmail("");
        setMobile("");
        setRole('');
        setNotification(0);
    }
    const updateClubUser = (event) => {
        event.preventDefault();
        setLoading(false);
        if(validateForm()) {
            // if(validateEmail(email)) {
                const clubUserData = {
                    "user_id" : clubUserID,
                    "username": username,
                    "email":email,
                    "mobile":mobile,
                    "is_club_owner":false,
                    "role":parseInt(role),
                    "club_id":club_id,
                    "notification": notification
                }
                updateClubUserData(clubUserData, user?.authtoken)
                .then((response) => {
                    if(response.status === 200) {
                        emptyTheForm();
                        setVisible(false);
                        setLoading(!loading);
                        toast.success(userTextData.clubUser.clubUserUpdateMsg);
                    }
                }).catch((error) => {
                    dispatch({
                        type: 'IS_ERROR',
                        payload: {
                          error: error
                        },
                    });
                    history.push("/something-went-wrong");
                });
            // } else {
            //     const newErrors = {};
            //     newErrors.email = userTextData.clubUser.emailErrorMsg;
            //     setErrors(newErrors);
            // }
        }
    }
    const addClubUserConfirm = (event) => {
        event.preventDefault();
        if(validateForm()) {
            // if(validateEmail(email)) {
                const clubUserData = {
                    "username": username,
                    "email":email,
                    "mobile":mobile,
                    "is_club_owner":false,
                    "role":parseInt(role),
                    "club_id":club_id,
                    "notification": notification
                }
                addClubUser(clubUserData, user?.authtoken)
                .then((response) => {
                    if(response.status === 200) {
                        setVisible(false);
                        setLoading(!loading);
                        emptyTheForm();
                        toast.success(userTextData.clubUser.clubUserAddMsg);
                    }
                }).catch((error) => {
                    dispatch({
                        type: 'IS_ERROR',
                        payload: {
                          error: error
                        },
                    });
                    history.push("/something-went-wrong");
                });
            // } 
            // else {
            //     const newErrors = {};
            //     newErrors.email = userTextData.clubUser.emailErrorMsg;
            //     setErrors(newErrors);
            // }
        }
    }
    const footerContent = (
        <div className="d-flex justify-content-center align-items-center">
            <Button label={userTextData.clubUser.cancel} icon="pi pi-times" onClick={() => handleHideDialog()} className="p-button-text cancel-button" />
            <Button label={isEdit ? userTextData.clubUser.update : userTextData.clubUser.save} icon="pi pi-check" loading={loading} onClick={(event) => isEdit ? updateClubUser(event) : addClubUserConfirm(event)} autoFocus className="normal-button" />
        </div>
    );
    const handleHideDialog = () => {
        setVisible(false);
    }
    const showDialog = () => {
        setIsEdit(false);
        setErrors({});
        setVisible(true);
        setLoading(false);
    }
    const validateField = (fieldName, value) => {
        let error = "";
        if(fieldName === 'role' && value === '') {
            error = userTextData.clubUser.roleErrorMsg;
        }
        // if(fieldName === 'mobile' && value.length !== 10) {
        //     error = "please enter a valid mobile number"
        // }
        if(fieldName === 'notification' && value === 0) {
            delete errors.notification;
            setErrors(errors);
            return;
        }
        if(!value) {
          error = `${fieldName} is required`;
        }
        setErrors(prevErrors => ({
          ...prevErrors,
          [fieldName]: error
        }));
      
        return error;
    };
    const handleUsername = (fieldName, value) => {
        setUsername(value);
        validateField(fieldName, value);
    }
    const handleEmail = (fieldName, value) => {
        setEmail(value === "" ? "" : value);
        if(validateEmail(value)) {
            if ('email' in errors) {
                delete errors.email;
                setErrors(errors);
            }
        } else {
            const newErrors = {};
            newErrors.email = userTextData.clubUser.emailErrorMsg;
            setErrors(newErrors);
        }
    }
    const handleMobile = (fieldName, value) => {
        setMobile(value);
        validateField(fieldName, value);
    }
    const handleRole = (fieldName, value) => {
        setRole(value);
        validateField(fieldName, value);
    }
    const handleNotification = (fieldName, value) => {
        setNotification(value);
        validateField(fieldName, value);
    }
    const notificationType = (rowData) => {
        if(rowData.Notification === 0) {
            return <Tag icon="pi pi-times" severity="danger" value={userTextData.clubUser.notiDisabled}></Tag>
        } else if(rowData.Notification === 1) {
            return <Tag className="mr-2" icon="pi pi-comment" severity="info" value={userTextData.clubUser.notiSMS}></Tag>
        } else if(rowData.Notification === 2) {
            return <Tag className="mr-2" icon="pi pi-envelope" value={userTextData.clubUser.notiEmail}></Tag>
        } else {
            return <Tag className="mr-2" icon="pi pi-comments" severity="success" value={userTextData.clubUser.notiSMS_Email}></Tag>
        }
    }
    return (
        <>
            <ConfirmDialog />
            <Dialog header={isEdit ? userTextData.clubUser.editUser : userTextData.clubUser.addUser} visible={visible} style={{ width: '50rem' }} draggable={false} onHide={() => handleHideDialog()} footer={footerContent}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 pl-0">
                            <div className="form-group" style={{width:"100%"}}>
                                <label>{userTextData.clubUser.username}<span className="redStar">*</span></label>
                                <InputText 
                                    className={`form-control ${errors.username ? "is-invalid" : ""}`}
                                    value={username}
                                    name={userTextData.clubUser.username}
                                    style={{ display: 'flex'}}
                                    placeholder={userTextData.clubUser.username}
                                    onChange={(event) => handleUsername('username', event.target.value)}
                                />
                                {errors.username && (
                                  <div className="invalid-feedback">{errors.username}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6 pl-0">
                            <div className="form-group">
                                <label>{userTextData.clubUser.email}</label>
                                <InputText 
                                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                    value={email}
                                    name={userTextData.clubUser.email}
                                    style={{ display: 'flex'}}
                                    placeholder={userTextData.clubUser.email}
                                    onChange={(event) => handleEmail('email', event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 pl-0">
                            <div className="form-group">
                                <label>{userTextData.clubUser.mobile_no}<span className="redStar">*</span></label>
                                {/* <InputText 
                                    className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
                                    value={mobile}
                                    name="MobileNo"
                                    maxLength={10}
                                    keyfilter="int"
                                    style={{ display: 'flex'}}
                                    placeholder="Mobile No" 
                                    onChange={(event) => handleMobile('mobile', event.target.value)}
                                /> */}
                                <PhoneInput style={{height:'40px', padding: '8px 12px', borderRadius: '4px', border: '1px solid #E3E3E3', backgroundColor: '#FFFFFF'}} name="MobileNo" placeholder={userTextData.clubUser.mobileNumber} defaultCountry="US" value={mobile} onChange={(value) => handleMobile('mobile', value)}/>
                                {errors.mobile && (
                                  <div style={{width: '100%',marginTop: '.25rem',fontSize: '80%',color: '#dc3545'}}>{errors.mobile}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-6 pl-0">
                        <div className="form-group">
                            <label>{userTextData.clubUser.role}<span className="text-danger">*</span></label>
                            <div className={`card d-flex justify-content-center mb-0 form-control ${errors.role ? "is-invalid" : ""}`}>
                                <div className="d-flex gap-3 height-38">
                                    <div className="d-flex align-items-center ml-10">
                                        <RadioButton className="p-checkbox" inputId="manager" name="role" value="1" onChange={(e) => handleRole('role', e.value)} checked={role === '1'} />
                                        <label htmlFor="manager" className="ml-2 mb-0">{userTextData.clubUser.manager}</label>
                                    </div>
                                    <div className="d-flex align-items-center ml-10">
                                        <RadioButton className="p-checkbox" inputId="server" name="role" value="2" onChange={(e) => handleRole('role', e.value)} checked={role === '2'} />
                                        <label htmlFor="server" className="ml-2 mb-0">{userTextData.clubUser.server}</label>
                                    </div>
                                </div>
                            </div>
                            {errors.role && (
                                  <div className="invalid-feedback">{errors.role}</div>
                            )}
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pl-0">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="d-flex align-items-center">
                                    <label htmlFor="customCheckbox" className="p-checkbox-label" style={{ marginBottom: '0px' }}>
                                    {userTextData.clubUser.noti}<span className="redStar">*</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 pl-0">
                            <div className="d-flex justify-content-between">
                                {/* Desktop view */}
                                <div className="d-none d-md-flex align-items-center">
                                    <RadioButton
                                        className="p-checkbox"
                                        inputId="notallowed"
                                        name="notification"
                                        value={0}
                                        onChange={(e) => handleNotification('notification',e.value)}
                                        checked={notification === 0}
                                    />
                                    <label htmlFor="notallowed" className="ml-2 mb-0">
                                        {userTextData.clubUser.no_noti}
                                    </label>
                                </div>

                                <div className="d-none d-md-flex align-items-center ml-10">
                                    <RadioButton
                                        className="p-checkbox"
                                        inputId="sms"
                                        name="notification"
                                        value={1}
                                        onChange={(e) => handleNotification('notification',e.value)}
                                        checked={notification === 1}
                                    />
                                    <label htmlFor="sms" className="ml-2 mb-0">
                                        {userTextData.clubUser.sms_noti}
                                    </label>
                                </div>

                                <div className="d-none d-md-flex align-items-center ml-10">
                                    <RadioButton
                                        className="p-checkbox"
                                        inputId="email"
                                        name="notification"
                                        value={2}
                                        onChange={(e) => handleNotification('notification',e.value)}
                                        checked={notification === 2}
                                        disabled={!validateEmail(email)}
                                    />
                                    <label htmlFor="email" className="ml-2 mb-0" style={{color:!validateEmail(email) ? "lightgray": ""}}>
                                        {userTextData.clubUser.email_noti}
                                    </label>
                                </div>

                                <div className="d-none d-md-flex align-items-center ml-10">
                                    <RadioButton
                                        className="p-checkbox"
                                        inputId="both"
                                        name="notification"
                                        value={3}
                                        onChange={(e) => handleNotification('notification',e.value)}
                                        checked={notification === 3}
                                        disabled={!validateEmail(email)}
                                    />
                                    <label htmlFor="both" className="ml-2 mb-0" style={{color:!validateEmail(email) ? "lightgray": ""}}>
                                        {userTextData.clubUser.both}
                                    </label>
                                </div>
                                {/* Mobile view */}
                                <div className="d-md-none flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <RadioButton
                                            className="p-checkbox"
                                            inputId="notallowed"
                                            name="notification"
                                            value={0}
                                            onChange={(e) => handleNotification('notification',e.value)}
                                            checked={notification === 0}
                                        />
                                        <label htmlFor="notallowed" className="ml-2 mb-0">
                                            {userTextData.clubUser.no_noti}
                                        </label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            className="p-checkbox"
                                            inputId="sms"
                                            name="notification"
                                            value={1}
                                            onChange={(e) => handleNotification('notification',e.value)}
                                            checked={notification === 1}
                                        />
                                        <label htmlFor="sms" className="ml-2 mb-0">
                                            {userTextData.clubUser.sms_noti}
                                        </label>
                                    </div>
                                </div>

                                <div className="d-md-none flex-column ml-10">
                                    <div className="d-flex align-items-center mb-2">
                                        <RadioButton
                                            className="p-checkbox"
                                            inputId="email"
                                            name="notification"
                                            value={2}
                                            onChange={(e) => handleNotification('notification',e.value)}
                                            checked={notification === 2}
                                            disabled={email === null}
                                        />
                                        <label htmlFor="email" className="ml-2 mb-0" style={{color:email === null ? "lightgray": ""}}>
                                            {userTextData.clubUser.email_noti}
                                        </label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <RadioButton
                                            className="p-checkbox"
                                            inputId="both"
                                            name="notification"
                                            value={3}
                                            onChange={(e) => handleNotification('notification',e.value)}
                                            checked={notification === 3}
                                            disabled={email === null}
                                        />
                                        <label htmlFor="both" className="ml-2 mb-0" style={{color:email === null ? "lightgray": ""}}>
                                            {userTextData.clubUser.both}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {errors.notification && (
                                <div style={{fontSize:"80%", color:"#dc3545"}}>{errors.notification}</div>
                            )}
                        </div>
                    </div>
                </div>
            </Dialog>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                    <div className="row" style={{ paddingBottom: "1%" }}>
                        <div className="col-6 text-left">
                            <Button label={userTextData.clubUser.back} icon="pi pi-angle-left" link style={{textDecoration:"none"}} onClick={() => history.go(-1)}/>
                        </div>
                        <div className="col-6 text-right buttonParent">
                            <Button label={userTextData.clubUser.addNewUser} icon="pi pi-plus" className="normal-button btn-sm" onClick={() => showDialog()}/>
                        </div>
                    </div>
                        <Card title={userTextData.clubUser.userList}>
                        { 
                            <DataTable
                                value={clubUsers && clubUsers}
                                paginator rows={25} rowsPerPageOptions={[25, 50]}
                                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                totalRecords={clubUsers?.length}
                                first={first}
                                emptyMessage={userTextData.clubUser.no_record}
                                className="p-datatable-striped"
                            >
                                <Column field="UserName" header={userTextData.clubUser.username} sortable />
                                <Column field ={userTextData.clubUser.email} header={userTextData.clubUser.email} sortable />
                                <Column field={userTextData.clubUser.mobile} header={userTextData.clubUser.mobile} sortable />
                                <Column field="Role" header={userTextData.clubUser.role} sortable body={(rowData) => rowData.Role === 1 ? userTextData.clubUser.manager : userTextData.clubUser.server }/>
                                <Column field="Notification" header={userTextData.clubUser.noti_status} body={(rowData) => notificationType(rowData)} />
                                <Column field="IsAdmin" header={userTextData.clubUser.is_Admin} body={(rowData) => rowData.IsAdmin ? "Yes" : "No"}/>
                                <Column style={{ flex: '0 0 4rem' }} header={userTextData.clubUser.actions} body={(rowData)=>actionButtons(rowData)}/>
                            </DataTable> 
                        }
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClubUserList;
