import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { getBookingsList } from "api/bookings";
import { useSelector } from "react-redux";
import { Sidebar } from "primereact/sidebar";
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";

const BookingList = () => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [bookings, setBookings] = useState([]);
  const [visibleRight, setVisibleRight] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user?.authtoken) return;

    getBookingsList(user?.authtoken)
      .then((response) => {
        if (response.status === 200) {
          const formattedBookings = response.data.bookings.map((booking) => ({
            id: booking?.id,
            clubName: booking.ClubName,
            customerName: `${booking?.from_user?.first_name} ${
              booking?.from_user?.last_name || ""
            }`.trim(),
            date: booking?.request_date || "N/A",
            time: "N/A",
            guests: booking?.group_size || 0,
            status: getStatusLabel(booking?.request_status),
            payment: `$ `+ booking?.request_payment || 0,
            phone: booking?.from_user?.phone || "N/A",
            email: booking?.from_user?.email || "N/A",
            specialRequest: booking?.special_request || "None",
          }));
          setBookings(formattedBookings);
        }
      })
      .catch((error) => {
        console.error("Error fetching bookings:", error);
      });
  }, [user?.authtoken]);

  const getStatusLabel = (status) => {
    switch (status) {
      case -2:
        return "Payment Failed";
      case -1:
        return "Payment Pending";
      case 0:
        return "Customizing";
      case 1:
        return "Pending";
      case 2:
        return "In Progress";
      case 3:
        return "Completed";
      case 4:
        return "Rejected";
      case 5:
        return "Playing";
      case 6:
        return "Refunded";
      default:
        return "Unknown";
    }
  };

  const openSidebar = (booking) => {
    setSelectedBooking(booking);
    setVisibleRight(true);
  };

  const renderHeader = () => (
    <div className="flex justify-content-end">
      <span className="p-input-icon-left custom-search-input">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search Bookings"
        />
      </span>
    </div>
  );

  return (
    <>
      <ConfirmDialog />
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <Card title="Booking List">
            <DataTable
              value={bookings}
              paginator
              rows={10}
              header={renderHeader()}
              globalFilterFields={["customerName", "date", "time", "status"]}
              emptyMessage="No bookings found."
              className="p-datatable-striped"
            >
              <Column field="clubName" header="Club Name" sortable />
              <Column field="date" header="Date" sortable />
              <Column field="payment" header="Payment" sortable />
              <Column field="guests" header="Guests" sortable />
              <Column field="status" header="Status" sortable />
              <Column
                header="View"
                body={(rowData) => (
                  <Button
                    type="button"
                    className="p-button-sm p-button-text"
                    icon="pi pi-eye"
                    onClick={() => openSidebar(rowData)}
                  />
                )}
              />
            </DataTable>
          </Card>
        </div>
      </div>

      {/* Sidebar with Booking Details */}
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
        className="p-sidebar-sm"
      >
        <h3 className="text-center text-primary">Booking Details</h3>
        <Divider />
        {selectedBooking ? (
          <div>
            <div className="p-card p-shadow-3 p-3 rounded">
              <h3 className="text-xl font-bold text-secondary">
                {selectedBooking.clubName}
              </h3>
              <p className="text-gray-600">{selectedBooking.date}</p>

              <Divider />

              <h5 className="text-lg font-bold">Customer Info</h5>
              <p>
                <i className="pi pi-user mr-2 text-primary"></i>
                {selectedBooking.customerName}
              </p>
              <p>
                <i className="pi pi-envelope mr-2 text-primary"></i>
                {selectedBooking.email}
              </p>
              <p>
                <i className="pi pi-phone mr-2 text-primary"></i>
                {selectedBooking.phone}
              </p>

              <Divider />

              <h5 className="text-lg font-bold">Booking Info</h5>
              <p>
                <i className="pi pi-users mr-2 text-primary"></i>
                Guests: <strong>{selectedBooking.guests}</strong>
              </p>
              <p>
                <i className="pi pi-money-bill mr-2 text-primary"></i>
                Payment: <strong>${selectedBooking.payment}</strong>
              </p>
              <p>
                <i className="pi pi-info-circle mr-2 text-primary"></i>
                Status:{" "}
                <Tag
                  value={selectedBooking.status}
                  severity={
                    selectedBooking.status === "Completed"
                      ? "success"
                      : selectedBooking.status === "Rejected"
                      ? "danger"
                      : "warning"
                  }
                />
              </p>

              <Divider />

              <h5 className="text-lg font-bold">Special Request</h5>
              <p className="text-gray-700">{selectedBooking.specialRequest}</p>
            </div>
          </div>
        ) : (
          <p className="text-center">No booking selected.</p>
        )}
      </Sidebar>
    </>
  );
};

export default BookingList;
